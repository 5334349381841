body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color .2s ease-in-out;
}

html,
body,
#root,
.scroll {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: smooth;
}

#root {
  overflow: auto;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}


.caption {
  position: fixed;
  top: 0;
  right: 0;
  margin: 80px;
  color: white;
  font-size: 8em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
